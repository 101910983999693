import React, { Component, useState } from 'react';
import MediumPost from "../components/MediumPost";
import InstagramPost from "../components/InstagramPost";
import { StaticQuery, graphql } from "gatsby"
export default function BlogContent() {
  const [setView, setViewState] = useState("Insta");//medium


  function toggleView(view) {
    setViewState(view);
  }

  return (
    <div>
      <div className="btnContainer" style={{}}>
        <div style={{ paddingBottom: '2em', marginLeft: 'auto', marginRight: 'auto', width: "fit-content" }}>
          <button onClick={() => toggleView("Insta")} className={setView === "Insta" ? "btnLeft btnBlog btnActive" : "btnLeft btnBlog"}>
            Instagram
            </button>
          <button onClick={() => toggleView("Medium")} className={setView === "Medium" ? "btnRight btnBlog btnActive" : "btnRight btnBlog"}>
            Medium
            </button>
        </div>
      </div>

      <StaticQuery
        query={graphql`
                        query Medium {
                          allInstagramContent {
                            nodes {
                              permalink
                            }
                          }
                            allMediumPost(limit: 7, sort: { fields: createdAt, order: DESC }) {
                              totalCount
                              edges {
                                node {
                                  id
                                  uniqueSlug
                                  title
                                  createdAt(formatString: "MMM YYYY")
                                  virtuals {
                                    subtitle
                                    readingTime
                                    previewImage {
                                      imageId
                                    }
                                  }
                                  author {
                                    username
                                  }
                                }
                              }
                            }
                          }
                          `}

        render={({ allMediumPost, allInstagramContent }) => (
          <div className="row">
            <div className={setView === "Insta" ? "col-lg-6" : "col-lg-6 hiddenContainer"}>
              {allInstagramContent.nodes.map(post => (
                <InstagramPost url={post.permalink} />
              ))}

            </div>
            <div className={setView === "Medium" ? "col-lg-6" : "col-lg-6 hiddenContainer"}>
              {allMediumPost.edges.map(post => (
                <MediumPost image={post.node.virtuals.previewImage.imageId} title={post.node.title} slug={post.node.uniqueSlug} username={post.node.author.username} subtitle={post.node.virtuals.subtitle} />
              ))}

            </div>
          </div>

        )}
      />
    </div>
  );
}


import React, { Component } from 'react';
import Img from 'gatsby-image';
import "./instagramPost.css";

class InstagramPost extends Component {
    render() {
        return (
            <div style={{ maxWidth: '70vw', marginBottom: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className="instaShawdow" style={{
                    width: '100%', paddingBottom: "141%", position: "relative",
                    paddingTop: "30px", height: "0", overflow: "hidden",
                    margin: 'auto'

                }}>
                    <div style={{

                        width: "100%",
                        height: "100%",

                    }}>
                    </div>
                    <iframe
                        src={this.props.url + "embed"}
                        frameborder="0"
                        allowfullscreen
                        scrolling="no"
                        allowtransparency
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            boxShadow: "2px 1px 4px rgba(0, 0, 0, 0.25)"
                        }}

                    ><div style={{ boxShadow: "2px 1px 4px rgba(0, 0, 0, 0.25)", width: "100%", height: "100%" }}></div></iframe>
                </div>
            </div>
        );
    }
}


export default InstagramPost;
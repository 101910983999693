import React, { useState } from 'react';
import Layout from "../components/layout";
import Footer from "../components/Footer";
import BlogContent from "../components/BlogContent";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";
import "./blog.css";





export default function blog({ data }) {



  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog</title>
        <link rel="canonical" href="http://www.newnewyorkcapital.com/blog" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout>
        <BlogContent />

      </Layout>
      <Footer />
    </div >
  );

}

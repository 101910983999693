import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
const MEDIUM_CDN = "https://cdn-images-1.medium.com/max/400";
const MEDIUM_URL = "https://medium.com";

class MediumPost extends Component {
    render() {
        return (
            <div style={{
                maxWidth: '70vw', marginBottom: '2em', marginLeft: 'auto', marginRight: 'auto', boxShadow: "2px 1px 4px rgba(0, 0, 0, 0.25)", backgroundColor: "#F6F6F6", width: '100%'
            }
            }>
                <img height="auto" width="100%" src={`${MEDIUM_CDN}/${this.props.image}`} />
                <h5 style={{ padding: '1em' }}><Link to={`${MEDIUM_URL}/@${this.props.username}/${this.props.slug}`}>{this.props.title}</Link></h5>
                <p style={{ padding: '1em', paddingTop: '0' }}>{this.props.subtitle}</p>
            </div >
        );
    }
}

export default MediumPost;